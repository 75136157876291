<template>
  <base-layout class="wallet-view">
    <div class="card">
      <div class="text1">可提现(元)</div>
      <div class="text2">{{ formatNum2Str(withdrawMoney) }}</div>
      <div class="row m-t-20">
        <div>
          <p class="text1">结算中(元)</p>
          <p class="text3 color1">{{ formatNum2Str(settlementMoney) }}</p>
        </div>
        <div>
          <p class="text1">累计收入(元)</p>
          <p class="text3">{{ formatNum2Str(cumulativeMoney) }}</p>
        </div>
      </div>

      <van-cell
        class="cell"
        title="我的银行卡"
        icon="credit-pay"
        title-class="cell-title"
        is-link
        @click="toPageWalletCard"
      />
    </div>
  </base-layout>
</template>

<script>
import { selectRefereeWallet } from "@/api/index";
import { formatNum2Str } from "@/utils";

export default {
  data() {
    return {
      withdrawMoney: 0, // 可提现金额
      settlementMoney: 0, // 结算中金额
      cumulativeMoney: 0 // 累计金额
    };
  },

  async created() {
    document.title = "我的钱包";
    await this.fetchData();
  },

  methods: {
    formatNum2Str,
    async fetchData() {
      try {
        let { data } = await selectRefereeWallet();
        data = data || {};
        this.withdrawMoney = data.withdrawMoney || 0;
        this.settlementMoney = data.settlementMoney || 0;
        this.cumulativeMoney = data.cumulativeMoney || 0;
      } catch (error) {
        // TODO: 异常场景待处理
        console.error("error:", error);
      }
    },
    toPageWalletCard() {
      this.$router.push({ name: "WalletCard" });
    }
  }
};
</script>

<style lang="less" scoped>
.m-t-20 {
  margin-top: 20px;
}

.text1 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  color: #969696;
  text-align: center;
}
.text2 {
  margin-top: 8px 0 0 0;
  padding: 0;
  font-size: 40px;
  line-height: 40px;
  color: #191919;
  text-align: center;
}
.text3 {
  margin: 2px 0 0 0;
  padding: 0;
  font-size: 20px;
  line-height: 24px;
  color: #191919;
  text-align: center;
}

.color1 {
  color: #ff7b01;
}

.wallet-view {
  padding: 12px;
  background-color: #f3f6f7;
}

.card {
  padding: 20px 0 0 0;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.row {
  display: flex;
  padding-bottom: 22px;
  & > * {
    flex: 1;
  }
}

.cell {
  padding: 14px 16px;
  /* prettier-ignore */
  border-top: 0.5PX solid rgba(25, 25, 25, 0.06);
  font-size: 15px;
  color: #191919;

  /deep/ .cell-title {
    font-size: 15px;
    color: #191919;
  }
}
</style>
